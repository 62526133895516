import isEmpty from "lodash/isEmpty";
import { ScreenState } from "../store/screen/types";

interface MinimumSecureMediaPlatformSupportedVersion {
  [key: string]: string;
}

// Minimum required versions for each platform to fully support secure media caching
const minimumSecureMediaPlatformSupportedVersion: MinimumSecureMediaPlatformSupportedVersion = {
  screencloudos: "2.1.14",
  amazon: "4.6.18",
  android: "4.6.18",
  windows: "3.5.1",
  osx: "3.5.1",
  chromeos: "2.10.6",
  brightsign: "2.1.3",
  lgwebos: "2.0.2",
  samsungssp: "1.2.2",
  ios: "2.3.1",
};

// helper function to filter undefined/null and their types, useful mostly for filtering arrays
export function notUndefinedOrNull<T>(x: T | undefined | null): x is T {
  return x !== undefined && x !== null;
}

export interface ElementSize {
  width: number;
  height: number;
}

export function detectElementSize(element: HTMLElement): ElementSize {
  const width = element.offsetWidth;
  const height = element.offsetHeight;
  return {
    width,
    height,
  };
}

export function getUrlAndParams(): { url: URL; params: URLSearchParams } {
  const url = new URL(window.location.toString());
  const params = new URLSearchParams(window.location.search);
  return { url, params };
}

export function getStudioPlayerVersion(): string {
  return (
    process.env.REACT_APP_VERSION || process.env.REACT_APP_GITHASH || "N/A"
  );
}
/**
 * convert data from data value table in to query string
 * @param dataValue
 * @returns queryString
 */
export function dataValueToQueryString(
  dataValue: { [key: string]: string } | undefined
): string | null {
  const queryString = new URLSearchParams(dataValue);
  return queryString.toString();
}

/**
 * parsing url with params and extra paams from data value table
 * @param url a full url string
 * @param queryStrings[] a list of queryStrings
 * @returns finalURL
 */
export function parsingURLWithQueryString(
  url: string,
  queryStrings: (string | null)[]
): string {
  const splitHash = url.indexOf("#") > -1 ? url.split("#") : url;
  const hashesParams = Array.isArray(splitHash)
    ? splitHash
        .slice(1)
        .map((hash) => `#${hash}`)
        .join("")
    : "";
  const urlWithoutHash = Array.isArray(splitHash)
    ? splitHash.shift()
    : splitHash;

  const extraQuerParams = queryStrings?.filter((item) => item).join("&"); // join the query array

  let urlWithQueryParams = urlWithoutHash;
  if (extraQuerParams?.length > 0) {
    urlWithQueryParams =
      urlWithoutHash && urlWithoutHash?.indexOf("?") > -1
        ? urlWithoutHash + "&" + extraQuerParams
        : urlWithoutHash + "?" + extraQuerParams;
  }

  // a final url with query string and hash (if available)
  const finalURL = urlWithQueryParams + hashesParams;
  return finalURL;
}

export function isVersionLessThan(version1: string, version2: string) {
  const arr1 = version1.split(".").map(Number);
  const arr2 = version2.split(".").map(Number);

  // Compare each part of the version numbers
  for (let i = 0; i < Math.max(arr1.length, arr2.length); i++) {
    const num1 = arr1[i] || 0;
    const num2 = arr2[i] || 0;

    if (num1 < num2) {
      return true;
    } else if (num1 > num2) {
      return false;
    }
  }

  return false; // version1 is equal to version2
}

export function isLegacyAndroidIgnoreParams(
  native_player_version: NonNullable<
    ScreenState["deviceInfo"]["native_player"]
  >["version"],
  native_player_platform: NonNullable<
    ScreenState["deviceInfo"]["native_player"]
  >["platform"]
): boolean {
  if (!native_player_version && !native_player_platform) {
    return true;
  }

  if (
    isEmpty(native_player_platform) ||
    (native_player_platform && native_player_platform !== "android")
  ) {
    console.log(
      "native_player: ",
      native_player_platform,
      native_player_version
    );
    return false;
  }

  return !!(
    native_player_version &&
    isVersionLessThan(native_player_version, "4.6.15") &&
    native_player_platform === "android"
  );
}

// ref: https://www.notion.so/screencloud/Secure-Media-Content-9aec239e935b4761a97395c5a749dff9#01c75ad8da6b467688b56bc7bce8380e
export function ignoreToCacheTheseParamsOnOurNativePlayers(
  policy?: string
): string {
  if (!policy) {
    return "";
  }

  const parsedParams = new URLSearchParams(policy);
  const keys = parsedParams.keys();
  const keyArray = Array.from(keys);

  const legacyAndroidIgnoreKey = "ignore";
  const scIgnoreKey = "sc_ignore_params";

  /**
   * We decided sending both of the legacy (ignore) and sc (sc_ignore_params) keys due to race condition issue
   * which cause to cache the wrong params and create a miltiple cached files
   **/

  if (keyArray.length > 0) {
    const paramsKeys = keyArray.join(",");
    const legacyIgnoreParam = `&${legacyAndroidIgnoreKey}=${paramsKeys},${scIgnoreKey}`;
    const scIgnoreParam = `&${scIgnoreKey}=${paramsKeys},${legacyAndroidIgnoreKey}`;
    const ignoreParam = `${policy}${scIgnoreParam}${legacyIgnoreParam}`;
    return ignoreParam;
  }

  return policy;
}

export function isVersionGreaterThanOrEqual(v1: string, v2: string): boolean {
  const v1Parts = v1.split(".").map(Number);
  const v2Parts = v2.split(".").map(Number);

  // Compare each part of the version numbers
  for (let i = 0; i < Math.max(v1Parts.length, v2Parts.length); i++) {
    const v1Part = v1Parts[i] || 0;
    const v2Part = v2Parts[i] || 0;
    if (v1Part > v2Part) return true;
    if (v1Part < v2Part) return false;
  }
  // All parts are equal if we reach here
  return true;
}

export function isMediaCacheSupportedForScreen(screen: ScreenState): boolean {
  if (
    !screen.devicePlatform ||
    screen.devicePlatform === "browser" ||
    !screen.deviceInfo?.native_player?.version ||
    !minimumSecureMediaPlatformSupportedVersion[screen.devicePlatform]
  ) {
    return false;
  }

  const requiredVersion =
    minimumSecureMediaPlatformSupportedVersion[screen.devicePlatform];

  return isVersionGreaterThanOrEqual(
    screen.deviceInfo.native_player.version,
    requiredVersion
  );
}
